import React from 'react';

import { useDetailPopup } from 'shared/components/detail-popup/detail-popup.hook';
import { Anchor, AnchorButton } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { ClickableElementProps } from 'shared/components/click-stream/clickable-element-props';

import './detail-popup.component.scss';
import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerDetailView, unregisterDetailView } from 'store/epics/detail-view/detail-view.actions';
import { dismissToast } from '../toast/toast-interfaces';

type ClickEventType =
  React.MouseEvent<HTMLDivElement, MouseEvent>
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

interface TriggerProps extends ClickableElementProps {
  url: string;
  name: string;
  resourceTrigger?: ResourceKey;
  className?: string;
  btnPrimary?: boolean;
  btnDefault?: boolean;
  btnBlock?: boolean;
  btnClear?: boolean;
  btnLink?: boolean;
  mobileResponsive?: boolean;
  isLinkFromToast?: boolean;
  onClick?: (event: ClickEventType) => void;
  children?: ReactNode;
}

// Triggers popup window for detail pages
export const TriggerDetailPopup: React.FC<TriggerProps> = ({ url, name, resourceTrigger, isLinkFromToast, children, onClick, ...props }) => {

  const dispatch = useDispatch();
  const handleDetailClick = useCallback((e: ClickEventType) => {
    showDetailPopup(e);

    if (onClick) {
      onClick(e);
    }

    if (isLinkFromToast) {
      dispatch(dismissToast(props.data));
    }
  }, [props.data, onClick]);

  const showDetailPopup = useDetailPopup(url, name);

  // used to determine whether to apply the btn class to the <a /> tag or not on the child clickable element.
  const isAnchorButton = props.btnPrimary || props.btnDefault || props.btnBlock || props.btnClear || props.mobileResponsive;

  return (
    <React.Fragment>
      {isAnchorButton
        ? <AnchorButton
          id={name}
          href={url}
          target="_blank"
          resource={resourceTrigger}
          onClick={handleDetailClick}
          {...props}
        >
          {children}
          <span className="visually-hidden">{' '}<Translation resource="OPENS_IN_NEW_WINDOW" /></span>
        </AnchorButton>
        : <Anchor
          id={name}
          href={url}
          target="_blank"
          resource={resourceTrigger}
          onClick={handleDetailClick}
          {...props}
        >
          {children}
          <span className="visually-hidden">{' '}<Translation resource="OPENS_IN_NEW_WINDOW" /></span>
        </Anchor>
      }
    </React.Fragment>
  );
};

// Wraps detail page content that is in popup window
export const DetailPopupWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(registerDetailView());

    return () => { dispatch(unregisterDetailView()); };
  }, []);
  // this will wrap the content that is passed in
  return (
    <main className="detail-popup-wrapper">
      {children}
    </main>
  );
};
